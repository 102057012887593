import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'

Vue.use(VueMeta)
Vue.use(VueRouter)

function getRoutes() {
  return [
    {
      path: '/',
      name: 'Stopped',
      meta: { footer: false },
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('@/views/Stopped.vue'),
    },
  ]
}

const routes = getRoutes()

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash, behavior: 'smooth' }
    }
    return { x: 0, y: 0 }
  },
})

export default router
