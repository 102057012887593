<template>
  <div class="footer">
    <div class="footer__line-wrapper">
      <div class="line line--default line__first"></div>
      <div class="line line--primary line__second"></div>
    </div>
    <div class="footer__seperator"></div>

    <div class="footer__content" v-if="$route.meta.footer !== false">
      <router-link to="terms">Se konkurransevilkårene</router-link>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.footer {
  $translate: -1vw;
  height: 80vh;

  @include respond-below('phone') {
    height: 40vh;
  }

  background: var(--red);

  position: relative;

  &__content {
    background-color: var(--red);
    text-align: center;
    padding: 15px 1rem;
    font-size: 0.9rem;

    a {
      font-weight: 600;
      color: white;
    }
  }

  &__line-wrapper {
    z-index: 3;
    width: 100%;
    background-color: var(--red);
    padding-top: 3rem;

    transform: skewY(5deg);

    position: absolute;
    top: $translate;

    @include respond-above('large-screen') {
      top: calc(#{$translate} + 2rem);
      padding-top: 4rem;
    }

    .line {
      height: 1.6rem;
    }
  }

  &__seperator {
    position: relative;
    height: inherit;

    background: url('../assets/Cover.jpg');
    clip-path: polygon(0 0%, 100% 6vw, 100% 100%, 0% 100%);
    background-size: cover;
    background-position: 0 50%;

    @include respond-above('large-screen') {
      clip-path: polygon(0 0%, 100% 40%, 100% 100%, 0% 100%);
      transform: translateY($translate);
    }
  }
}
</style>
