<template>
  <div id="app">
    <router-view />
    <!-- <FooterComponent /> -->
  </div>
</template>

<script>
import FooterComponent from '@/components/Footer.vue'

export default {
  components: {
    FooterComponent,
  },
}
</script>

<style lang="scss">
@import 'src/styles/main';

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
</style>
